import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
	Container,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import ConfirmationModal from "../../components/ConfirmationModal"

import { Can } from "../Can"

import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},
	container: {
		border: "1px solid grey",
		padding: "5px 0px",
		margin: "5px 0px",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	title: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
	details: Yup.string().required("Required"),
});

const UTCToLocal = (timestamp) => {
	return moment(timestamp)
};

const LocalToUTC = (timestamp) => {
	return moment(timestamp).toISOString();
};

const formatDateValue = (timestamp) => {
	const format = "YYYY-MM-DDTHH:mm";
	const date = new Date(timestamp);
	return moment(date).format(format);
};

const BookingModal = ({ open, onClose, bookingId }) => {
	const classes = useStyles();

	const history = useHistory();

	const initialState = {
		title: "",
		details: "",
		opening: formatDateValue(moment().toDate()),
		ends: formatDateValue(moment().add(60, "minute").toDate()),
	};

	const [booking, setbooking] = useState(initialState);
	const [contacts, setcontacts] = useState([]);
	const [status, setStatus] = useState("");
	const [contactid, setcontactid] = useState(null);

	const [bUser, setBUser] = useState(null);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		const fetchUser = async () => {
			if (!bookingId) return;
			try {
				const { data } = await api.get(`/booking/${bookingId}`);
				setbooking(prevState => {
					return { 
						...prevState,
						...data,
						opening: UTCToLocal(data.opening),
						ends: UTCToLocal(data.ends)
					};
				});

				setcontactid(data.contactId);
				setStatus(data.status);

				if(data.userId && user.profile === "admin"){
					const user = await api.get(`/users/${data.userId}`);
					setBUser(user.data);
				}else{
					setBUser(null);
				}
				
				
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
		fetchContacts();
	}, [bookingId, open]);

	const handleClose = () => {
		onClose();
		setbooking(initialState);
	};

	const isFirstAfterSecond = (timestamp1, timestamp2) => {
		const date1 = new Date(timestamp1);
		const date2 = new Date(timestamp2);
		return date1 > date2;
	};

	const handleSaveUser = async values => {
		try {
			if(isFirstAfterSecond(values.opening,values.ends)){
				toast.error("Solt can not end before it starts !", {
					toastId: values.opening,
				});
				return;
			}

			

			if (bookingId) {
				await api.put(`/booking/${bookingId}`, {
					...values,
					contactId: contactid,
					opening: LocalToUTC(values.opening),
					ends: LocalToUTC(values.ends),
				});
			} else {
				await api.post("/booking", {
					...values,
					contactId: contactid,
					opening: LocalToUTC(values.opening),
					ends: LocalToUTC(values.ends),
				});
			}
			toast.success("Booking Added SuccessFully");
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [deletingbooking, setDeletingbooking] = useState(null);

	const handleDeletebooking = async (id) => {
		try {
			await api.delete(`/booking/${id}`);
			toast.success("booking successfully deleted");
		} catch (err) {
			toastError(err);
		}
		setDeletingbooking(null);
	};

	const fetchContacts = async () => {
		try {
			const { data } = await api.get("/contacts/");
			setcontacts(data.contacts)
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenTicket = async (contactId) => {
		if (!contactId) return;
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user?.id,
				status: "open",
				passCheck: false
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const repeatBookingOption = [
		{
			key: "none",
			title: "Does not repeat",
		},
		{
			key: "daily",
			title: "Repeat Daily for a Month",
		},
		{
			key: "weekly",
			title: "Repeat Weekly At Same Time",
		},
		{
			key: "monthly",
			title: "Repeat Monthy At Same Time",
		},
		{
			key: "annually",
			title: "Repeat Annually At Same Time",
		},
		{
			key: "week-days",
			title: "Repeat Week Days a Month (Monday to Friday)",
		},
	];

	return (
		<div className={classes.root}>
			<ConfirmationModal
				title={deletingbooking && `Delete`}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={() => {
					handleDeletebooking(deletingbooking.id)
					handleClose();
				}}
			>
				Delete this booking slot ? 
			</ConfirmationModal>

			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{bookingId ? `Edit Booking Slot` : `Add Booking Slot`}
				</DialogTitle>
				<Formik
					initialValues={booking}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={"Title"}
									autoFocus
									name="title"
									error={touched.title && Boolean(errors.title)}
									helperText={touched.title && errors.title}
									variant="outlined"
									margin="dense"
									style={{margin: "10px 0px"}}
									fullWidth
								/>
								<TextField
									label={"Slot Starts"}
									type="datetime-local"
									name="opening"
									className={classes.textField}
									fullWidth
									variant="outlined"
									margin="dense"
									value={formatDateValue(booking.opening)}
									onChange={(v) => {
										const x = v.target.value.substring(0,16);
										values.opening = x
										setbooking((b) => {
											return {
												opening: x,
												...b
											};
										});
									}}
									style={{margin: "10px 0px"}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									label={"Slot Ends"}
									type="datetime-local"
									name="ends"
									variant="outlined"
									margin="dense"
									value={formatDateValue(booking.ends)}
									className={classes.textField}
									onChange={(v) => {
										const x = v.target.value.substring(0,16);
										values.ends = x;
										setbooking((b) => {
											return {
												ends: x,
												...b
											};
										});
									}}
									fullWidth
									style={{margin: "10px 0px"}}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<Field
									as={TextField}
									label={"Details"}
									autoFocus
									name="details"
									error={touched.details && Boolean(errors.details)}
									helperText={touched.details && errors.details}
									variant="outlined"
									margin="dense"
									fullWidth
									style={{margin: "10px 0px"}}
								/>
								<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
									<InputLabel id="profile-selection-input-label">
										Booking Status
									</InputLabel>
									<Select
										autoFocus
										variant="outlined"
										label="Booking Status"
										margin="dense"
										className={classes.select}
										value={status}
										onChange={e => {
											setStatus(e.target.value)
											values.status = e.target.value;
											setbooking(() => {
												return { ...values, status: e.target.value };
											});
										}}
									>
										{["empty", "pending", "confirmed"].map((s) =>
											<MenuItem selected={s === status} value={s}> {s} </MenuItem>
										)}
									</Select>
								</FormControl>

								{
									!bookingId && (
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel id="profile-selection-input-label">
												Repeat Booking
											</InputLabel>
											<Select
												autoFocus
												variant="outlined"
												label="Booking Status"
												margin="dense"
												className={classes.select}
												onChange={e => {
													values.repeat = e.target.value;
												}}
											>
												{repeatBookingOption.map((s) =>
													<MenuItem value={s.key}> {s.title} </MenuItem>
												)}
											</Select>
										</FormControl>
									)
								}

								<label style={{margin: "10px 0px"}}>
									<Field
										type="checkbox"
										name="booked"
										onChange={(v) => {
											values.booked = !values.booked;
											setbooking(() => {
												return {
													booked: !values.booked,
													...values
												};
											});
										}}
									/>
									Is Booked 
								</label>
								{	booking.booked &&
									(<div style={{margin: "10px 0px", display: "flex", gap: "10px" , alignItems: "center"}} >
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<Field
												as={Select}
												value={contactid}
												onChange={(e) => {setcontactid(e.target.value)}}
												label={"Select Booked Contact"}
											>
												<MenuItem value={null}> none </MenuItem>
												{contacts.map((c) => (
													<MenuItem key={c.id} value={c.id}>{c.name} - {c.phone}</MenuItem>
												))}
											</Field>
										</FormControl>
										<Button
											onClick={() => handleOpenTicket(contactid)}
											style={{ height: "40px"}}
											color="primary"
											disabled={isSubmitting}
											variant="outlined"
										>
											Text
										</Button>
									</div>)
								}
								<Can 
									role={user.profile}
									perform="store:showBelongsTo"
									yes={() => bUser && (
										<div className={classes.container}>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Belongs To
												</Typography>
											</Container>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Username: <b> {bUser.name} </b>
												</Typography>
											</Container>
											<Container maxWidth="sm">
												<Typography variant="heading2" >
													 Email: <b> {bUser.email} </b>
												</Typography>
											</Container>
										</div>
									)}
								/>
								<Typography variant="heading2" >
									<br/> <br/>Note: If "Is Booked" is checked slot will not be visible to user
								</Typography>
							</DialogContent>
							<DialogActions>
								{ bookingId && 
									<Button
										onClick={() => {
											setConfirmModalOpen(true)
											setDeletingbooking(booking)
										}}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										Delete
									</Button>
								}
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{bookingId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default BookingModal;
