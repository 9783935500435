import React, { useState, useEffect, useContext } from "react";
import { parseISO, format } from "date-fns";

import {
  Paper,
  Dialog,
  IconButton,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/Map";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  btnWrapper: {
    position: "relative",
  },
  select: {
    flex: 1,
    width: "100%",
    margin: "10px 0px"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    border: "1px solid grey",
    padding: "5px 0px",
    margin: "5px 0px",
  },
  buttonUpload: {
    margin: "5px 0px",
    minWidth: 120,
  },
  showmore: {
    margin: "10px 0px",
  }
}));

const ViewLogModal = ({ open, onClose, trackableId }) => {
  const classes = useStyles();

  const [logs, setLogs] = useState([]);
  const [trackable, setTrackable] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const { user } = useContext(AuthContext);
  
  const typesm = {
    "CHECKIN": "Check In",
    "CHECKOUT": "Check Out",
    "MAINTENANCE": "Maintainance",
    "REPAIR": "Repair",
    "INCIDENT": "Incident",
    "USAGE": "Usage",
    "STATUS_CHANGE": "Status Change",
    "LOCATION_CHANGE": "Location Change",
    "CONNECTION_CHANGE": "Connection Change",
    "ERROR": "Error",
    "INFO": "Info",
    "BREAK": "Break",
    "LUNCH_BREAK": "Lunch Break",
    "MEETING": "Meeting",
    "TASK_COMPLETED": "Task Complete",
    "TASK_STARTED": "Task Started",
    "WARNING": "Warning"
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (trackableId === null) return;

      try {
        const { data } = await api.get(`/logs`, {
          params: {
            trackableItemId: trackableId === 0 ? null : trackableId,
            userId: trackableId === 0 ? user.id : null,
            pageNumber: pageNumber
          }
        });
        // const response = await api.get(`/trackables/${trackableId}`);
        // setTrackable( response.data );

        setLogs((old) => [...old, ...data.logs]);
        setHasMore(data.hasMore);

      } catch (err) {
        toastError(err);
      }
    };
    fetchUser();
  }, [trackableId, open, pageNumber]);

  const handleClose = () => {
    setPageNumber(1)
    setLogs([]);
    onClose();
  };

  const openMaps = (log) => {
    if (log.lat && log.lng) {
      const url = "http://maps.google.com/?q=" + log.lat + "," + log.lng;
      window.open(url, '_blank')
      return;
    }

    const url = "http://maps.google.com/?q=" + log.address;
    window.open(url, '_blank')
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    loadMore();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const snakeToCamel = str => str.toLowerCase().replace(/([-_][a-z])/g, group => capitalizeFirstLetter( group.replace('-', ' ').replace('_', ' ') ) );

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          All Time Log
        </DialogTitle>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {
                  !trackableId && (
                    <TableCell align="center">
                      ID
                    </TableCell>
                  )
                }
                {
                  !trackableId && (
                    <TableCell align="center">
                      Name
                    </TableCell>
                  )
                }
                <TableCell align="center">
                  Status
                </TableCell>
                <TableCell align="center">
                  Time Log
                </TableCell>
                <TableCell align="center">
                  Note
                </TableCell>
                <TableCell align="center">
                  Location
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  {
                      !trackableId && (
                      <TableCell align="center"> {log.trackableItemId} </TableCell>
                    )
                  }
                  {
                     !trackableId && (
                      <TableCell align="center"> {log.trackableItem.name} </TableCell>
                    )
                  }

                  <TableCell align="center"> { typesm[log.type] } </TableCell>

                  <TableCell align="center"> {format(parseISO(log.createdAt), "dd/MM/yyyy hh:mm:ss a")} </TableCell>
                  <TableCell align="center"> {log.note ? log.note.substring(0, 25) : ""} </TableCell>
                  <TableCell align="center">
                    {
                      (log.address != "" || log.lat != null) && (
                        <IconButton
                          size="small"
                          onClick={() => openMaps(log)}
                        >
                          <MapIcon />
                        </IconButton>
                      )
                    }
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </TableBody>
          </Table>
          {
            hasMore && <Button
              color="primary"
              variant="outlined"
              className={classes.showmore}
              onClick={() => handleScroll()}
            >
              Show older logs
            </Button>
          }
        </Paper>
      </Dialog>
    </div>
  );
};

export default ViewLogModal;
