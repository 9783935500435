import React, { useState, useEffect } from "react";

import {
  CssBaseline,
  Typography,
  Container,
  Badge,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/landingpagelogo.png";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Menu from "@material-ui/icons/Store";
import CheckOutlined from "@material-ui/icons/Payment";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Done from "@material-ui/icons/Done";
import RemoveShoppingCart from "@material-ui/icons/RemoveShoppingCart";
import Grid from "@material-ui/core/Grid";
import toastError from "../../errors/toastError";
import TabPanel from "../../components/TabPanel";
import api from "../../services/api";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cpaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoStyle: {
    margin: "30px auto",
    width: "25%",
    maxWidth: "200px",
  },
  mainFormContainer: {
    height: "90vh",
    display: "flex",
    alignItems: "center"
  },
  mainFormContainer: {
    height: "100%",
    width: "100%",
  },
  chatPapper: {
    display: "flex",
    height: "100%",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  mainProductsContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    margin: "40px 0px"
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },
  tab: {
    minWidth: 120,
    width: 120,
  },
  mainFormContainer: {
    margin: "40px 0px",
    maxWidth: "2000px"
  },
  formContainer: {
    width: "100%",
    maxWidth: "700px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cart: {
    position: "fixed",
    bottom: "10%",
    left: "80%",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryStyle: {
    margin: "20px 0px",
    padding: "10px",
    textAlign: "center",
    border: "1px solid black",
    borderRadius: "25px",
  }
}));

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const orderSchema = Yup.object().shape({
  orderEmail: Yup.string().email("Enter valid email").required("Required"),
  shippingAddress: Yup.string().required("Required"),
  contactName: Yup.string().required("Required"),
  whatsAppContact: Yup.string().required("Required")
    .matches(phoneRegExp, 'Enter valid Whatsapp number')
    .min(10, "number is too short")
    .max(12, "number is too long"),
});

const orderSchemaB = Yup.object().shape({
  orderEmail: Yup.string().email("Enter valid email").required("Required"),
  shippingAddress: Yup.string().required("Required"),
});

const optSchema = Yup.object().shape({
  otp: Yup.string().required("Required")
    .min(6, "number is too short")
    .max(6, "number is too long"),
});

const ProductCard = ({ product, addCart, removeFromCart, count }) => {
  const [image, setImage] = useState(0);

  const images = [ product.thumbnail , product.image ];

  const iterateImages = () => {
    if(image < images.length - 1){
      let newImage = image + 1;
      if(images[newImage] == null || images[newImage] == undefined || images[newImage] == "" ){
        setImage(0);
        return;
      }
      setImage(newImage);
    }else{
      setImage(0);
    }
  };

  const $addToCart = (id) => {
    if(count + 1 > product.stock){
      toast.error(`${product.name} is out of stock`, {
				toastId: `${product.name} is out of stock`,
			});
      return;
    }
    addCart(id);
  };

  return (
    <Card style={{ width: "200px" }} raised={true}>
      <CardMedia
        component="img"
        height="140"
        image={images[image]}
        onClick={() => iterateImages()}
      />
      <CardContent style={{ padding: "8px" }} >
        <Typography gutterBottom variant="h5" component="div">
          <Badge badgeContent={count} color="error">
            {product.name}
          </Badge>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.price} MYR
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={(e) => $addToCart(product.id)} size="small">
          <ShoppingCart />
        </Button>
        {
          count > 0 &&
          <Button variant="outlined" onClick={(e) => removeFromCart(product.id)} size="small">
            <RemoveShoppingCart />
          </Button>
        }
      </CardActions>
    </Card>
  );
}

const Storefront = () => {
  const classes = useStyles();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const cid = params.get('cid');
  const uid = params.get('uid');

  const [tab, setTab] = useState("products");
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [cart, setCart] = useState([]);
  const [settings, setSettings] = useState([]);

  const [hash, setHash] = useState("");
  const [createOrder, setCreateOrder] = useState("");

  const initialState = {
    shippingAddress: "",
    orderEmail: "",
    whatsAppContact: "",
    contactName: ""
  };

  const [order, setOrder] = useState(initialState);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setIsLoading(true);
        if(uid){
          const { data } = await api.get("/storefront", {
            params: {
              userId: uid
            }
          });

          setProducts(data.products);
          setCategories(data.categories);
          setSettings(data.settings);
        }
      } catch (err) {
        toastError(err);
      }
      setIsLoading(false);
    };
    fetchSession();
  }, []);

  const handleChangeTab = (newValue) => {
    setTab(newValue);
  };

  const addToCart = (id) => {
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      if (product.id === id) {
        setCart(items => [...items, product]);
        break;
      }
    }
  }

  const removeFromCart = (id) => {
    const indexToRemove = cart.findIndex(item => item.id === id);
    if (indexToRemove !== -1) {
      const updatedCart = [...cart.slice(0, indexToRemove), ...cart.slice(indexToRemove + 1)];
      setCart(updatedCart);
    }
  }

  const getSettingValue = key => {
    let keySearch = key + ":" + uid;
		const value = settings.find(s => s.key === keySearch);
		return value?.value ?? "";
	};

  const countInCart = (id) => {
    let count = 0;
    cart.forEach((item) => {
      if (item.id === id) {
        count++;
      }
    });
    return count;
  };

  const drawMenu = () => {
    return (
      categories.map((category) => {
        return (
          <Container style={{ margin: "20px 0px" }} >
            <Typography className={classes.categoryStyle} variant="h5" gutterBottom>
              {category.name}
              {category.thumbnail && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={category.thumbnail}
                  />
              )}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ gap: "40px" }}
            >
              {products.map((product) => product.categoryId !== category.id || product.stock < 1 ? <></> :
                <ProductCard
                  product={product}
                  count={countInCart(product.id)}
                  addCart={(id) => addToCart(id)}
                  removeFromCart={(id) => removeFromCart(id)}
                />
              )}
            </Grid>
          </Container>
        );
      })
    );
  };

  const drawCart = () => {
    let qtyMap = {};
    let uniqueCount = 0;
    let total = 0;

    cart.forEach((items) => {
      const key = items.id;
      total = total + items.price;
      if (qtyMap[key] != null) {
        let c = qtyMap[key].qty + 1;
        qtyMap[key] = {
          qty: c,
          item: items
        };
      } else {
        uniqueCount++;
        qtyMap[key] = {
          qty: 1,
          item: items
        };
      }
    });

    let uniqueIds = Object.keys(qtyMap);

    const tax = parseFloat(getSettingValue("storeTax"));
    const amount = total * (1 + (tax / 100));
    const amountTaxInclusive = Math.round(amount * 100) / 100;
    let mainDisplay = [];

    mainDisplay.push(
      <Grid container style={{ margin: "20px 0px", display: "flex", width: "100%", justifyContent: "space-between", maxWidth: "700px", padding: "0px 24px" }} >
        <Grid item >
          <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
            Item
          </Typography>
        </Grid>
        <Grid item >
          <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
            Price
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
            Quantity
          </Typography>
        </Grid>
      </Grid>
    );

    const cartItems = Array.from({ length: uniqueCount }, (v, i) => {
      const x = qtyMap[uniqueIds[i]];
      return <Grid container style={{ margin: "20px 0px", display: "flex", width: "100%", justifyContent: "space-between", maxWidth: "700px", padding: "0px 24px" }} >
        <Grid item >
          <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
            {x.item.name}
          </Typography>
        </Grid>
        <Grid item >
          <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
            {x.item.price} MYR
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
            {x.qty}x
          </Typography>
        </Grid>
      </Grid>
    });

    mainDisplay.push(cartItems);

    mainDisplay.push(
      <Container style={{ margin: "20px 0px", display: "flex", width: "100%", justifyContent: "space-between", maxWidth: "700px" }} >
        <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
          Subtotal
        </Typography>
        <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
          {total} MYR
        </Typography>
      </Container>
    );

    mainDisplay.push(
      <Container style={{ margin: "20px 0px", display: "flex", width: "100%", justifyContent: "space-between", maxWidth: "700px" }} >
        <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
          Tax
        </Typography>
        <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
          {tax}%
        </Typography>
      </Container>
    );

    mainDisplay.push(
      <Container style={{ margin: "20px 0px", display: "flex", width: "100%", justifyContent: "space-between", maxWidth: "700px" }} >
        <Typography style={{ margin: "20px 0px" }} variant="subtitle2" gutterBottom>
          Total
        </Typography>
        <Typography style={{ margin: "20px 0px" }} variant="body2" gutterBottom>
          {amountTaxInclusive} MYR
        </Typography>
      </Container>
    );

    return mainDisplay;
  };

  const handleSaveOrder = async values => {
    try {
      const itemIds = cart.map((item) => item.id);

      const { data } = await api.post("/orders", {
        contactId: cid,
        userId: uid,
        items: itemIds,
        ...values
      });

      setHash(data.hash);
      setCreateOrder(data.order);

      handleChangeTab("confirmation");
      toast.success("OTP is sent to you !");
    } catch (err) {
      toastError(err);
    }
  };

  const handleConfrimOrder = async values => {
    try {
      await api.put(`/orders/${createOrder.id}`, {
        hash,
        otp: values.otp,
        contactId: createOrder.contactId,
        orderStatus: "confirmed"
      });

      setIsDone(true)
      toast.success("Orders Added SuccessFully");
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <Paper elevation={0} square className={classes.tabsHeader}>
              <Tabs
                value={tab}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab
                  value={"products"}
                  icon={<Menu />}
                  label="Products"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  value={"checkout"}
                  icon={<CheckOutlined />}
                  label="Checkout"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  value={"confirmation"}
                  icon={<Done />}
                  label="Confirmation"
                  classes={{ root: classes.tab }}
                />
              </Tabs>
            </Paper>
            {
              tab === "products" && (
                <Container className={classes.cart}>
                  <Badge badgeContent={cart.length} color="error">
                    <ShoppingCart />
                  </Badge>
                </Container>
              )
            }
            <TabPanel value={tab} name="products" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="lg">
                <div className={classes.paper}>
                  <img className={classes.logoStyle} src={logo} alt="Logo" />
                  <Typography component="h1" variant="h5">
                    TanyaJE Store
                  </Typography>
                  {!isLoading && drawMenu()}
                  {
                    cart.length > 0 && (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ margin: "20px 0px", width: "200px" }}
                        onClick={() => handleChangeTab("checkout")}
                      >
                        Checkout
                      </Button>
                    )
                  }
                </div>
              </Container>
            </TabPanel>

            <TabPanel value={tab} name="checkout" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.cpaper}>
                  <div className={classes.formContainer}>
                    <img className={classes.logoStyle} src={logo} alt="Logo" />
                    <Typography component="h1" variant="h5">
                      Checkout
                    </Typography>
                    {drawCart()}
                  </div>
                  <div className={classes.formContainer}>
                    <Typography component="h1" variant="h5">
                      Details
                    </Typography>
                    <Formik
                      initialValues={order}
                      enableReinitialize={true}
                      validationSchema={cid ? orderSchemaB : orderSchema}
                      onSubmit={(values, actions) => {
                        setTimeout(() => {
                          handleSaveOrder(values);
                          actions.setSubmitting(false);
                        }, 400);
                      }}
                    >
                      {({ touched, errors, isSubmitting, values }) => (
                        <Form className={classes.formContainer}>
                          <Field
                            as={TextField}
                            label="Email"
                            autoFocus
                            name="orderEmail"
                            error={touched.orderEmail && Boolean(errors.orderEmail)}
                            helperText={touched.orderEmail && errors.orderEmail}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            label="Shipping Address"
                            name="shippingAddress"
                            error={touched.shippingAddress && Boolean(errors.shippingAddress)}
                            helperText={touched.shippingAddress && errors.shippingAddress}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                          {
                            !cid &&
                            <Field
                              as={TextField}
                              label="WhatsApp Contact"
                              name="whatsAppContact"
                              error={touched.whatsAppContact && Boolean(errors.whatsAppContact)}
                              helperText={touched.whatsAppContact && errors.whatsAppContact}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          }
                          {
                            !cid &&
                            <Field
                              as={TextField}
                              label="Full Name"
                              name="contactName"
                              error={touched.contactName && Boolean(errors.contactName)}
                              helperText={touched.contactName && errors.contactName}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          }
                          <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                            <InputLabel id="profile-selection-input-label">
                              Payment Method
                            </InputLabel>
                            <Select
                              label="Select automated flow"
                              autoFocus
                              helperText={"Automated Flow"}
                              variant="outlined"
                              margin="dense"
                              className={classes.select}
                              onChange={e => {
                                values.paymentMethod = e.target.value;
                                setOrder(() => {
                                  return { ...values, paymentMethod: e.target.value };
                                });
                              }}
                            >
                              <MenuItem value="cod"> Cash On Delivery </MenuItem>
                              <MenuItem value="card"> Debt/Credit Card </MenuItem>
                              <MenuItem value="transfer"> Bank Transfer </MenuItem>
                            </Select>
                          </FormControl>

                          {isSubmitting && <CircularProgress />}

                          {
                            !isSubmitting && (
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={{ margin: "20px 0px 10px 0px", width: "200px" }}
                              >
                                Confrim Order
                              </Button>
                            )
                          }

                          <Button
                            color="primary"
                            type="button"
                            variant="outlined"
                            style={{ margin: "10px 0px", width: "200px" }}
                            onClick={() => handleChangeTab("products")}
                          >
                            Return
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Container>
            </TabPanel>
            <TabPanel value={tab} name="confirmation" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <img className={classes.logoStyle} src={logo} alt="Logo" />
                  {
                    isDone && (
                      <Typography component="h1" variant="h5">
                        Order Confrimed
                      </Typography>
                    )
                  }
                  {
                    !isDone && (
                      <>
                        <Typography component="h1" variant="h5">
                          Confrim
                        </Typography>
                        <Formik
                          initialValues={order}
                          enableReinitialize={true}
                          validationSchema={optSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              handleConfrimOrder(values);
                              actions.setSubmitting(false);
                            }, 400);
                          }}
                        >
                          {({ touched, errors, isSubmitting, values }) => (
                            <Form style={{ width: "100%", maxWidth: "700px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <Field
                                as={TextField}
                                label="OTP"
                                autoFocus
                                name="otp"
                                error={touched.otp && Boolean(errors.otp)}
                                helperText={touched.otp && errors.otp}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                              {isSubmitting && <CircularProgress />}
                              {
                                !isSubmitting && (
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{ margin: "10px 0px", width: "200px" }}
                                  >
                                    Verfiy
                                  </Button>
                                )
                              }
                            </Form>
                          )}
                        </Formik>
                      </>
                    )
                  }
                </div>
              </Container>
            </TabPanel>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};

export default Storefront;
