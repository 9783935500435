import React, { useState, useEffect, useRef} from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
	},
	paperColumn: {
		padding: theme.spacing(2),
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		marginBottom: 12,
	},
	settingOption: {
		marginLeft: "auto",
	},
	settingFullOption: {
		width: "100%",
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [autoPromtText, setAutoPromtText] = useState("");
	const [saDetailsText, setSaDetailsText] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			try {
				setIsLoading(true);
				const { data } = await api.get("/settings");
				setSettings(data);

				setAutoPromtText(getSettingValue("autoPromotText"));
				setSaDetailsText(getSettingValue("saDetailsText"));
			} catch (err) {
				toastError(err);
			}
			setIsLoading(false);
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const saveSettings = async (selectedValue,settingKey) => {
		try {
			setIsLoading(true);
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
		setIsLoading(false);
	};

	const getSettingValue = key => {
		const value = settings.find(s => s.key === key);
		return value?.value ?? "";
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")}
				</Typography>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
					</Select>
				</Paper>

				<Paper className={classes.paperColumn}>
					<Typography variant="body1">
						Auto Prompt Text
					</Typography>
					{ 
						!isLoading && 
						(<TextField
							onChange={(e) => setAutoPromtText(e.target.value)}
							name="autoPromotText"
							className={classes.settingFullOption}
							defaultValue={
								settings && settings.length > 0 && getSettingValue("autoPromotText")
							}
						/>)
					}
					<Button
						variant="outlined"
						margin="dense"
						style={{marginTop: "20px"}}
						native
						onClick={() => saveSettings(autoPromtText,"autoPromotText")}
					> Save </Button>
				</Paper>

				<Paper className={classes.paperColumn}>
					<Typography variant="body1">
						SA Detials Text
					</Typography>
					{
						!isLoading && (
							<TextField
								name="saDetailsText"
								onChange={(e) => setSaDetailsText(e.target.value)}
								className={classes.settingFullOption}
								defaultValue={
									settings && settings.length > 0 && getSettingValue("saDetailsText")
								}
							/>
						)
					}
					<Button
						variant="outlined"
						margin="dense"
						style={{marginTop: "20px"}}
						native
						onClick={() => saveSettings(saDetailsText,"saDetailsText")}
					> Save </Button>
				</Paper>

			</Container>
		</div>
	);
};

export default Settings;
