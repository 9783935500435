import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Container,
	Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import UploadField from "../UploadFeild"

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},
	select: {
		flex: 1,
		width: "100%",
		margin: "10px 0px"
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	container: {
		border: "1px solid grey",
		padding: "5px 0px",
		margin: "5px 0px",
	},
	buttonUpload: {
		margin: "5px 0px",
		minWidth: 120,
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
	price: Yup.number().required("Required"),
	weight: Yup.number(),
	stock: Yup.number().required("Required"),
	description: Yup.string(),
	image: Yup.string(),
	thumbnail: Yup.string(),
	categoryId: Yup.string(),
});

const ProductModal = ({ open, onClose, userId, categories }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		price: 0,
		weight: 0,
		stock: 0,
		description: "",
		image: "",
		thumbnail: "",
		categoryId: null,
	};

	const [product, setProduct] = useState(initialState);
	const { user } = useContext(AuthContext);
	const [bUser, setBUser] = useState(null);

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/products/${userId}`);
				setProduct(prevState => {
					return { ...prevState, ...data };
				});

				if(data.userId && user.profile === "admin"){
					const user = await api.get(`/users/${data.userId}`);
					setBUser(user.data);
				}else{
					setBUser(null);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setProduct(initialState);
	};

	const handleSaveUser = async values => {
		try {
			if (userId) {
				await api.put(`/products/${userId}`, values);
			} else {
				await api.post("/products", values);
			}
			toast.success("Product Added SuccessFully");
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId ? `Edit Product` : `Add Product`}
				</DialogTitle>
				<Formik
					initialValues={product}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values, setFieldValue }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("userModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<Field
									as={TextField}
									label="Description"
									name="description"
									error={touched.description && Boolean(errors.description)}
									helperText={touched.description && errors.description}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<UploadField
									label={"Thumbnail"}
									initalValue={product.thumbnail}
									onChange={(v) => {
										values.thumbnail = v;
										setProduct(() => {
											return { ...values, thumbnail: v };
										});
									}}
								/>
								<UploadField
									label={"Image"}
									initalValue={product.image}
									onChange={(v) => {
										values.image = v;
										setProduct(() => {
											return { ...values, image: v };
										});
									}}
								/>
								<Field
									as={TextField}
									label="Price"
									name="price"
									error={touched.price && Boolean(errors.price)}
									helperText={touched.price && errors.price}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<Field
									as={TextField}
									label="Stock"
									autoFocus
									name="stock"
									error={touched.stock && Boolean(errors.stock)}
									helperText={touched.stock && errors.stock}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<Field
									as={TextField}
									label="Weight in grams (g)"
									autoFocus
									name="weight"
									error={touched.weight && Boolean(errors.weight)}
									helperText={touched.weight && errors.weight}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
									<InputLabel id="profile-selection-input-label">
										Category
									</InputLabel>
									<Select
										label="Select automated flow"
										autoFocus
										helperText={"Automated Flow"}
										variant="outlined"
										margin="dense"
										className={classes.select}
										value={product.categoryId}
										onChange={e => {
											values.categoryId = e.target.value;
											setProduct(() => {
												return { ...values, categoryId: e.target.value };
											});
										}}
									>
										{categories.map((q) => <MenuItem selected={product.categoryId === q.id} value={q.id}> {q.name} </MenuItem>)}
									</Select>
								</FormControl>
								<Can
									role={user.profile}
									perform="store:showBelongsTo"
									yes={() => bUser && (
										<div className={classes.container}>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Belongs To
												</Typography>
											</Container>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Username: <b> {bUser.name} </b>
												</Typography>
											</Container>
											<Container maxWidth="sm">
												<Typography variant="heading2" >
													 Email: <b> {bUser.email} </b>
												</Typography>
											</Container>
										</div>
									)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId ? `${i18n.t("userModal.buttons.okEdit")}` : `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ProductModal;
