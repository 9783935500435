import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AppleIcon from "@material-ui/icons/Storefront";
import SettingsIcon from "@material-ui/icons/Settings";
import SortIcon from "@material-ui/icons/Sort";
import Grid from "@material-ui/core/Grid";
import toastError from "../../errors/toastError";
import TabPanel from "../../components/TabPanel";
import Products from "../../components/Products";
import Categories from "../../components/Categories";
import Orders from "../../components/Orders";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },
  root: {
    padding: "64px 0px",
    overflow: 'auto'
  },
  chatPapper: {
    display: "flex",
    height: "100%",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },
  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  tab: {
    minWidth: 120,
    width: 120,
  },
  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },
  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },
  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },
  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },
  badge: {
    right: "-10px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
  paperColumn: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: 12,
  },
  settingOption: {
    marginLeft: "auto",
  },
  settingFullOption: {
    width: "100%",
    marginLeft: "auto",
  },
}));


const Store = () => {
  const classes = useStyles();

  const [tab, setTab] = useState("open");
  const [isLoading, setIsLoading] = useState(false);

  const [settings, setSettings] = useState([]);

  const { user } = useContext(AuthContext);

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const saveSettings = async (selectedValue, settingKey) => {
    try {
      setIsLoading(true);

      if (settingKey === "storeTax") {
        if (selectedValue > 100) {
          throw "tax needs to be 100% or less"
        }

        if (selectedValue < 0) {
          throw "tax needs to be 0% or more"
        }
      }

      let key = settingKey;
      if(user.profile === "user"){
        key += ":" + user.id;
      }

      await api.put(`/settings/${key}`, {
        value: selectedValue,
      });

      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
      setIsLoading(false);
    };
    setTab("products");
    fetchSession();
  }, []);

  const getSettingValue = key => {
    let keySearch = key;

    if(user.profile === "user"){
      keySearch += ":" + user.id;
    }

    const value = settings.find(s => s.key === keySearch);
    return value?.value ?? "";
  };

  const setSettingValue = (e, key) => {
    let keySearch = key;

    if(user.profile === "user"){
      keySearch += ":" + user.id;
    }

    const value = settings.find(s => s.key === keySearch);
    if(value){
      value.value = e.target.value;
      setSettings(settings => [
        ...settings.filter((s) => s.key !== key),
        value
      ])
    }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Can
            role={user.profile}
            perform="store:access"
            data={user.storeEnabled}
            no={() => (
              <Container className={classes.container} maxWidth="sm">
                <Typography variant="h2" gutterBottom>
                  No Access Permission
                </Typography>
              </Container>
            )}
            yes={() => (
              <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
                <Paper elevation={0} square className={classes.tabsHeader}>
                  <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                  >
                    <Tab
                      value={"products"}
                      icon={<AppleIcon />}
                      label="Products"
                      classes={{ root: classes.tab }}
                    />
                    <Tab
                      value={"categories"}
                      icon={<AppleIcon />}
                      label="Categories"
                      classes={{ root: classes.tab }}
                    />
                    <Tab
                      value={"orders"}
                      icon={<SortIcon />}
                      label="Orders"
                      classes={{ root: classes.tab }}
                    />
                    <Tab
                      value={"settings"}
                      icon={<SettingsIcon />}
                      label="Settings"
                      classes={{ root: classes.tab }}
                    />
                  </Tabs>
                </Paper>
                <TabPanel value={tab} name="products" className={classes.ticketsWrapper}>
                  <Products />
                </TabPanel>
                <TabPanel value={tab} name="orders" className={classes.ticketsWrapper}>
                  <Orders />
                </TabPanel>
                <TabPanel value={tab} name="categories" className={classes.ticketsWrapper}>
                  <Categories />
                </TabPanel>
                <TabPanel value={tab} name="settings" className={classes.ticketsWrapper}>
                  <div className={classes.root}>
                    <Container className={classes.container} maxWidth="sm">
                      <Typography variant="body2" gutterBottom>
                        Settings
                      </Typography>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Greeting Text
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              name="storeGreeting"
                              onChange={(e) => setSettingValue(e, "storeGreeting")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeGreeting")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeGreeting"), "storeGreeting")}
                        > Save </Button>
                      </Paper>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Tax {"(%)"}
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              type="number"
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              name="storeTax"
                              onChange={(e) => setSettingValue(e, "storeTax")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeTax")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeTax"), "storeTax")}
                        > Save </Button>
                      </Paper>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Order Summary
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              name="storeOrderSummary"
                              onChange={(e) => setSettingValue(e, "storeOrderSummary")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeOrderSummary")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeOrderSummary"), "storeOrderSummary")}
                        > Save </Button>
                      </Paper>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Cash On Delivery Payment Details
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              name="storeCod"
                              onChange={(e) => setSettingValue(e, "storeCod")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeCod")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeCod"), "storeCod")}
                        > Save </Button>
                      </Paper>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Bank Transfer Payment Details
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              name="storeBankTransfer"
                              onChange={(e) => setSettingValue(e, "storeBankTransfer")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeBankTransfer")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeBankTransfer"), "storeBankTransfer")}
                        > Save </Button>
                      </Paper>
                      <Paper className={classes.paperColumn}>
                        <Typography variant="body1">
                          Store Card Payment Details
                        </Typography>
                        {
                          !isLoading && (
                            <TextField
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              name="storeCard"
                              onChange={(e) => setSettingValue(e, "storeCard")}
                              className={classes.settingFullOption}
                              defaultValue={
                                settings && settings.length > 0 && getSettingValue("storeCard")
                              }
                            />
                          )
                        }
                        <Button
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "20px" }}
                          native
                          onClick={() => saveSettings(getSettingValue("storeCard"), "storeCard")}
                        > Save </Button>
                      </Paper>
                    </Container>
                  </div>
                </TabPanel>
              </Paper>
            )}
          />
        </Grid>
      </div>
    </div>
  );
};

export default Store;
