


const timestampFormater = (timestamp) => {
    const date = new Date(timestamp);
    return date.toString().substring(0,25);
};
  

export {
    timestampFormater
};