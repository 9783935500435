import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";

import { timestampFormater } from "../../services/formater";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Delete from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../TableRowSkeleton";
import ConfirmationModal from "../ConfirmationModal";
import OrderModal from "../OrderModal";

import MainHeader from "../MainHeader";
import Title from "../Title";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import MainContainer from "../MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  select: {
    flex: 1,
    width: "100%",
    margin: "0px 0px"
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_ORDERS") {
    const orders = action.payload;
    const newContacts = [];

    orders.forEach((order) => {
      const orderIndex = state.findIndex((c) => c.id === order.id);
      if (orderIndex !== -1) {
        state[orderIndex] = order;
      } else {
        newContacts.push(order);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_ORDERS") {
    const order = action.payload;
    const orderIndex = state.findIndex((c) => c.id === order.id);

    if (orderIndex !== -1) {
      state[orderIndex] = order;
      return [...state];
    } else {
      return [order, ...state];
    }
  }

  if (action.type === "DELETE_ORDERS") {
    const orderId = action.payload;

    const orderIndex = state.findIndex((c) => c.id === orderId);
    if (orderIndex !== -1) {
      state.splice(orderIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Orders = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [orderStatus, setSearchOrderStatus] = useState("");
  const [selectedOrdersId, setSelectedOrderId] = useState(null);
  const [ordersModalOpen, setOrderModalOpen] = useState(false);
  const [deletingOrders, setDeletingOrder] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [orders, dispatch] = useReducer(reducer, []);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    setPageNumber(1);
    dispatch({ type: "RESET" });
  }, [orderStatus]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchOrders = async () => {
        try {
          const { data } = await api.get("/orders", {
            params: { pageNumber, orderStatus, searchParam },
          });

          dispatch({ type: "RESET" });
          dispatch({ type: "LOAD_ORDERS", payload: data.orders });

          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchOrders();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber, orderStatus, searchParam]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("order", (data) => {
      if (user.profile === "user" && data.userId !== user.id) {
        return;
      }

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_ORDERS", payload: data.order });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_ORDERS", payload: +data.orderId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const hadleEditOrder = (id) => {
    setSelectedOrderId(id);
    setOrderModalOpen(true);
  };

  const handleDeleteOrder = async (id) => {
    try {
      await api.delete(`/orders/${id}`);
      toast.success("Order Deleted");
    } catch (err) {
      toastError(err);
    }
    setDeletingOrder(null);
    setSearchOrderStatus("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleCheckboxSelection = (e, cid) => {
    setSelectedOrderIds(ids =>
      e.target.checked === true ? [...ids, cid] : ids.filter((id) => id !== cid)
    );
  };

  const handleCloseOrderModal = () => {
    setSelectedOrderId(null);
    setOrderModalOpen(false);
    setPageNumber(1);
  };

  const orderStatuses = ["pending", "confirmed", "started", "delivered", "done"];

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  return (
    <MainContainer className={classes.mainContainer}>

      <ConfirmationModal
        title={
          deletingOrders &&
          `Delete Order ${deletingOrders.id} ?`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() => handleDeleteOrder(deletingOrders.id)}
      >
        Are you you want to delete this order ?
      </ConfirmationModal>

      <OrderModal
        open={ordersModalOpen}
        onClose={handleCloseOrderModal}
        aria-labelledby="form-dialog-title"
        orderId={selectedOrdersId}
      />

      <MainHeader>
        <Title> Orders </Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder="Search Orders"
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <FormControl variant="outlined" margin="dense" style={{ width: "200px" }} >
            <InputLabel id="profile-selection-input-label">
              Filter By Order Status
            </InputLabel>
            <Select
              autoF ocus
              variant="outlined"
              margin="dense"
              className={classes.select}
              onChange={(e) => {
                if (e.target.value) {
                  setSearchOrderStatus(e.target.value.trim());
                } else {
                  setSearchOrderStatus("");
                }
              }}
            >
              <MenuItem> Select </MenuItem>
              {orderStatuses.map((s) =>
                <MenuItem value={s}> {s} </MenuItem>
              )}
            </Select>
          </FormControl>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                Select
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell align="center">
                Status
              </TableCell>
              <TableCell align="center">
                Contact Email
              </TableCell>
              <TableCell align="center">
                Created At
              </TableCell>
              <TableCell align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    <input type="checkbox" onChange={(e) => handleCheckboxSelection(e, order.id)} />
                  </TableCell>
                  <TableCell>{order.id}</TableCell>
                  <TableCell align="center">{order.orderStatus}</TableCell>
                  <TableCell align="center">{(order?.orderEmail ?? "").substring(0, 10)}</TableCell>
                  <TableCell align="center">{timestampFormater(order.createdAt)}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setDeletingOrder(order);
                        setConfirmOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => hadleEditOrder(order.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Orders;
