import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import UploadField from "../UploadFeild"

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  select: {
    flex: 1,
    width: "100%",
    margin: "10px 0px"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    border: "1px solid grey",
    padding: "5px 0px",
    margin: "5px 0px",
  },
  buttonUpload: {
    margin: "5px 0px",
    minWidth: 120,
  }
}));

const UserSchema = Yup.object().shape({
  note: Yup.string(),
  address: Yup.string(),
  type: Yup.string().required("Required"),
});

const LogModal = ({ open, onClose, trackableId }) => {
  const classes = useStyles();

  const initialState = {
    note: "",
    address: "",
    type: null,
  };

  const typesm = {
    "CHECKIN": "Check In",
    "CHECKOUT": "Check Out",
    "MAINTENANCE": "Maintainance",
    "REPAIR": "Repair",
    "INCIDENT": "Incident",
    "USAGE": "Usage",
    "STATUS_CHANGE": "Status Change",
    "LOCATION_CHANGE": "Location Change",
    "CONNECTION_CHANGE": "Connection Change",
    "ERROR": "Error",
    "INFO": "Info",
    "BREAK": "Break",
    "LUNCH_BREAK": "Lunch Break",
    "MEETING": "Meeting",
    "TASK_COMPLETED": "Task Complete",
    "TASK_STARTED": "Task Started",
    "WARNING": "Warning"
  };

  const [log, setLogs] = useState(initialState);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const handleClose = () => {
    setLogs(initialState);
    onClose();
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition);
    } else {
      toast.success("Geolocation is not supported by this browser.");
    }
  }

  const setPosition = (position) => {
    setLat(position.coords.latitude)
    setLng(position.coords.longitude)
  }

  const handleSaveTrackable = async values => {
    try {
      getLocation();
      
      await api.post("/logs", {
        lat: lat,
        lng: lng,
        trackableItemId: trackableId,
        ...values
      });
      toast.success("Log Added SuccessFully");
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const snakeToCamel = str => str.toLowerCase().replace(/([-_][a-z])/g, group =>group.replace('-', ' ').replace('_', ' '));

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Add Log
        </DialogTitle>
        <Formik
          initialValues={log}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTrackable(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label="Note"
                  autoFocus
                  name="note"
                  error={touched.note && Boolean(errors.note)}
                  helperText={touched.note && errors.note}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Field
                  as={TextField}
                  label="Address"
                  name="address"
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                  <InputLabel id="profile-selection-input-label">
                    Type
                  </InputLabel>
                  <Select
                    label="Select Type"
                    autoFocus
                    helperText={"Types"}
                    variant="outlined"
                    margin="dense"
                    className={classes.select}
                    value={log.type}
                    onChange={e => {
                      values.type = e.target.value;
                      setLogs(() => {
                        return { ...values, type: e.target.value };
                      });
                    }}
                  >
                    {Object.entries(typesm).map((q) => <MenuItem selected={log.type === q[0]} value={ q[0] }> { q[1] } </MenuItem>)}

                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  Add
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LogModal;
