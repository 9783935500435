import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import NoteAdd from "@material-ui/icons/NoteAdd";

import UploadField from "../UploadFeild"

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },
  select: {
    flex: 1,
    width: "100%",
    margin: "10px 0px"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    border: "1px solid grey",
    padding: "5px 0px",
    margin: "5px 0px",
  },
  buttonUpload: {
    margin: "5px 0px",
    minWidth: 120,
  }
}));

const UserSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  desc: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
});

const TrackableModal = ({ open, onClose, trackableId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    desc: "",
    type: null,
  };

  const types = [
    "VEHICLE",
    "EQUIPMENT",
    "DEVICE",
    "FACILITY",
    "PERSONNEL",
    "DOCUMENT",
    "INVENTORY",
    "ASSET",
    "SERVICE",
    "PROPERTY",
    "CHECK-IN",
    "CHECK-OUT",
  ];

  const [trackable, setTrackable] = useState(initialState);
  const { user } = useContext(AuthContext);
  const [bUser, setBUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      if (!trackableId) return;
      try {
        const { data } = await api.get(`/trackables/${trackableId}`);
        setTrackable(prevState => {
          return { ...prevState, ...data };
        });

        if (data.userId && user.profile === "admin") {
          const user = await api.get(`/users/${data.userId}`);
          setBUser(user.data);
        } else {
          setBUser(null);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [trackableId, open]);

  const handleClose = () => {
    onClose();
    setTrackable(initialState);
  };

  const handleSaveTrackable = async values => {
    try {
      if (trackableId) {
        await api.put(`/trackables/${trackableId}`, values);
      } else {
        await api.post("/trackables", values);
      }
      toast.success("Trackeable Added SuccessFully");
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const snakeToCamel = str => str.toLowerCase().replace(/([-_][a-z])/g, group =>group.replace('-', ' ').replace('_', ' '));

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {trackableId ? `Edit Trackable` : `Add Trackable`}
        </DialogTitle>
        <Formik
          initialValues={trackable}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTrackable(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("userModal.form.name")}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Field
                  as={TextField}
                  label="Description"
                  name="desc"
                  error={touched.desc && Boolean(errors.desc)}
                  helperText={touched.desc && errors.desc}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                {
                  trackableId && <Field
                    as={TextField}
                    label="NFC Key To Copy"
                    name="key"
                    value={window.location.hostname + `/trackable-front?tid=` + trackableId} 
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                }
                <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                  <InputLabel id="profile-selection-input-label">
                    Type
                  </InputLabel>
                  <Select
                    label="Select Type"
                    autoFocus
                    helperText={"Types"}
                    variant="outlined"
                    margin="dense"
                    className={classes.select}
                    value={trackable.type}
                    onChange={e => {
                      values.type = e.target.value;
                      setTrackable(() => {
                        return { ...values, type: e.target.value };
                      });
                    }}
                  >
                    {types.map((q) => <MenuItem selected={trackable.type === q} value={q}> {snakeToCamel(q)} </MenuItem>)}
                  </Select>
                </FormControl>
                <Can
                  role={user.profile}
                  perform="trackable:showBelongsTo"
                  yes={() => bUser && (
                    <div className={classes.container}>
                      <Container maxWidth="sm">
                        <Typography variant="heading2" >
                          Belongs To
                        </Typography>
                      </Container>
                      <Container maxWidth="sm">
                        <Typography variant="heading2" >
                          Username: <b> {bUser.name} </b>
                        </Typography>
                      </Container>
                      <Container maxWidth="sm">
                        <Typography variant="heading2" >
                          Email: <b> {bUser.email} </b>
                        </Typography>
                      </Container>
                    </div>
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {trackableId ? `${i18n.t("userModal.buttons.okEdit")}` : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default TrackableModal;
