import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import NoteAdd from "@material-ui/icons/NoteAdd";
import RemoveRedEyeOutlined from "@material-ui/icons/RemoveRedEyeOutlined";
import FileCopy from "@material-ui/icons/FileCopy";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
  Typography,
  Container
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Can } from "../../components/Can";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TrackableModal from "../../components/TrackableModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import ViewLogModal from "../../components/ViewLogModal";
import LogModal from "../../components/LogModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_TRACKABLE") {
    const trackables = action.payload;
    const newTrackables = [];

    trackables.forEach((trackable) => {
      const trackableIndex = state.findIndex((q) => q.id === trackable.id);
      if (trackableIndex !== -1) {
        state[trackableIndex] = trackable;
      } else {
        newTrackables.push(trackable);
      }
    });

    return [...state, ...newTrackables];
  }

  if (action.type === "UPDATE_TRACKABLES") {
    const trackable = action.payload;
    const trackableIndex = state.findIndex((q) => q.id === trackable.id);

    if (trackableIndex !== -1) {
      state[trackableIndex] = trackable;
      return [...state];
    } else {
      return [trackable, ...state];
    }
  }

  if (action.type === "DELETE_TRACKABLES") {
    const trackableId = action.payload;

    const trackableIndex = state.findIndex((q) => q.id === trackableId);
    if (trackableIndex !== -1) {
      state.splice(trackableIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Trackables = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");

  const [trackables, dispatch] = useReducer(reducer, []);
  const [selectedTrackables, setSelectedTrackables] = useState(null);
  const [selectedTrackableToCreateLog, setTrackableToCreateLog] = useState(null);
  const [selectedTrackableToViewLogs, setSelectedTrackableToViewLogs] = useState(null);
  const [trackablesModalOpen, setTrackablesModalOpen] = useState(false);
  const [logModalOpen, setLogsModalOpen] = useState(false);
  const [logViewModalOpen, setLogsViewModalOpen] = useState(false);
  const [deletingTrackables, setDeletingTrackables] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTrackables = async () => {
        try {
          const { data } = await api.get("/trackables/", {
            params: { name: searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_TRACKABLE", payload: data.trackableItems });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchTrackables();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenTrackablesModal = () => {
    setSelectedTrackables(null);
    setTrackablesModalOpen(true);
  };

  const handleCloseTrackablesModal = () => {
    setSelectedTrackables(null);
    setTrackablesModalOpen(false);

    setPageNumber(2);
    setPageNumber(1);
  };

  const handleCloseLogsModal = () => {
    setTrackableToCreateLog(null);
    setLogsModalOpen(false);
  };

  const handleCloseViewLogsModal = () => {
    setSelectedTrackableToViewLogs(null);
    setLogsViewModalOpen(false);
  };

  const handleEditTrackables = (trackable) => {
    setSelectedTrackables(trackable);
    setTrackablesModalOpen(true);
  };

  const handleLogCreateModal = (trackable) => {
    setTrackableToCreateLog(trackable);
    setLogsModalOpen(true);
  };

  const handleLogViewModal = (trackable) => {
    setSelectedTrackableToViewLogs(trackable);
    setLogsViewModalOpen(true);
  };

  const handleDeleteTrackables = async (trackableId) => {
    try {
      await api.delete(`/trackables/${trackableId}`);
      toast.success("Item Successfully Deleted");

      setDeletingTrackables(null);
      setPageNumber(2);
      setPageNumber(1);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const copy = async (trackable) => {
    try {
      await CheckPermission();
      navigator.clipboard.writeText(`respond.digitalmu-nfc:${trackable.id}`);
    } catch (e) {
      toast.error(`Copy from here instead: respond.digitalmu-nfc:${trackable.id}`);
    }
  };

  async function CheckPermission(){
    const writePerm = await navigator.permissions.query({name: 'clipboard-write', allowWithoutGesture: false });
    
    // Will be 'granted', 'denied' or 'prompt':
    if(writePerm.state == "denied"){
      toast.error("Clip board permissions denied");
    }
  }

  const scan = async () => {
    try {
      if ("NDEFReader" in window) {
        const ndef = new window.NDEFReader();
        await ndef.scan();
        toast.error("Scan started, put nfc card closer to phone");

        ndef.addEventListener("readingerror", () => {
          toast.error("Scan failed, please try again");
        });

        ndef.addEventListener("reading", async ({ message, serialNumber }) => {
          for (const record of message.records) {
            switch (record.recordType) {
              case 'text':
                setLoading(true);

                const textDecoder = new TextDecoder(record.encoding);
                const cardText = textDecoder.decode(record.data).split(":");

                const tid = cardText[1]; // scan from card

                const { data } = await api.get(`/trackables/${tid}`);

                handleLogCreateModal(data);

                setLoading(false);
                break;
            }
          }
        });
      } else {
        toast.error("NFC scanner not supported");
      }
    } catch (error) {
      toast.error("Scan failed, please try again");
    }
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <Can
        role={user.profile}
        perform="trackable:access"
        data={user.trackableEnabled}
        no={() => (
          <Container className={classes.container} maxWidth="sm">
            <Typography variant="h2" gutterBottom>
              No Access Permission
            </Typography>
          </Container>
        )}
        yes={() => (
          <>
            <ConfirmationModal
              title={
                deletingTrackables &&
                `Delete Trackable ${deletingTrackables.shortcut
                }?`
              }
              open={confirmModalOpen}
              onClose={setConfirmModalOpen}
              onConfirm={() => handleDeleteTrackables(deletingTrackables.id)}
            >
              Are you sure you want to delete Trackable
            </ConfirmationModal>
            <TrackableModal
              open={trackablesModalOpen}
              onClose={handleCloseTrackablesModal}
              aria-labelledby="form-dialog-title"
              trackableId={selectedTrackables && selectedTrackables.id}
            ></TrackableModal>
            <LogModal
              open={logModalOpen}
              onClose={handleCloseLogsModal}
              aria-labelledby="form-dialog-title"
              trackableId={selectedTrackableToCreateLog && selectedTrackableToCreateLog.id}
            ></LogModal>
            <ViewLogModal
              open={logViewModalOpen}
              onClose={handleCloseViewLogsModal}
              aria-labelledby="form-dialog-title"
              trackableId={selectedTrackableToViewLogs && selectedTrackableToViewLogs.id}
            ></ViewLogModal>

            <MainHeader>
              <Title> Trackables </Title>
              <MainHeaderButtonsWrapper>
                <TextField
                  placeholder="Search Trackables"
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenTrackablesModal}
                >
                  Create New Trackable
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={scan}
                >
                  Scan Card
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleLogViewModal({id: 0})
                  }}
                >
                  View All logs
                </Button>
              </MainHeaderButtonsWrapper>
            </MainHeader>

            <Paper
              className={classes.mainPaper}
              variant="outlined"
              onScroll={handleScroll}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      ID
                    </TableCell>
                    <TableCell align="center">
                      Name
                    </TableCell>
                    <TableCell align="center">
                      Type
                    </TableCell>
                    <TableCell align="center">
                     Info
                    </TableCell>
                    <TableCell align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {trackables.map((trackable) => (
                      <TableRow key={trackable.id}>
                        <TableCell align="center">{trackable.id}</TableCell>
                        <TableCell align="center">{trackable.name}</TableCell>
                        <TableCell align="center"> {trackable.type} </TableCell>
                        <TableCell align="center"> {trackable.desc} </TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={() => handleEditTrackables(trackable)}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={() => handleLogCreateModal(trackable)}
                          >
                            <NoteAdd />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={() => handleLogViewModal(trackable)}
                          >
                            <RemoveRedEyeOutlined />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setConfirmModalOpen(true);
                              setDeletingTrackables(trackable);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loading && <TableRowSkeleton columns={3} />}
                  </>
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      />
    </MainContainer>
  );
};

export default Trackables;
