import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	CardMedia
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	buttonUpload: {
		margin: "5px 0px",
		minWidth: 120,
	},
	buttonOptions: {
		display: "flex",
		gap: "10px",
		flexDirection: "column",
	}
}));


const UploadField = ({
	label,
	onChange,
	initalValue = null
}) => {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [successfull, setSuccessfull] = useState(false);

	const $onChange = async (event) => {
		setIsLoading(true);

		try {
			const file = event.currentTarget.files[0];

			const formData = new FormData();
			formData.append("image", file);

			const { data } = await api.post("/upload", formData);
			onChange(data.publicURL);

			toast.success("Uploaded Image SuccessFully");
			setSuccessfull(true);
		} catch (error) {
			toastError(error);
			setSuccessfull(false);
		}

		setIsLoading(false);
	}

	return (
		<Button
			component="label"
			variant="outlined"
			className={classes.buttonUpload}
			fullWidth
		>
			{
				isLoading ?
					(
						<CircularProgress
							size={24}
							className={classes.buttonProgress}
						/>
					) :
					(
						<div className={classes.buttonOptions}>
							<>
								Upload {label} {(successfull || initalValue) && " : Done uploading !"}
							</>
							<input type="file" accept="image/*" hidden onChange={$onChange} />
							{initalValue && (
								<CardMedia
									component="img"
									height="140"
									image={initalValue}
								/>
							)}
							{initalValue && (
								<Button
									component="label"
									variant="outlined"
									onClick={(e) => onChange("")}
								> X </Button>
							)}
						</div>
					)
			}

		</Button>
	);
};

export default UploadField;