import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Divider,
	Container,
	Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Can } from "../Can"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},
	container: {
		border: "1px solid grey",
		padding: "5px 0px",
		margin: "5px 0px",
	},
	btnWrapper: {
		position: "relative",
	},
	select: {
		flex: 1,
		width: "100%",
		margin: "10px 0px"
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const OrderSchema = Yup.object().shape({});

const OrderModal = ({ open, onClose, orderId }) => {
	const classes = useStyles();

	const history = useHistory();

	const [order, setOrder] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [bUser, setBUser] = useState(null);
	const { user } = useContext(AuthContext);

	const orderStatus = ["pending", "confirmed", "started", "delivered", "done"];

	useEffect(() => {
		const fetchUser = async () => {
			if (!orderId) return;
			setIsLoading(true);
			try {
				const { data } = await api.get(`/orders/${orderId}`);
				setOrder(data);

				if(data.userId && user.profile === "admin"){
					const user = await api.get(`/users/${data.userId}`);
					setBUser(user.data);
				}else{
					setBUser(null);
				}

			} catch (err) {
				toastError(err);
			}
			setIsLoading(false);
		};

		fetchUser();
	}, [orderId, open]);

	const levelObject = (obj, nkey = null) => {
		let newObj = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if(typeof value === 'object' && value !== null && !Array.isArray(value)){
					console.log(key)
					const objn = levelObject(value,key);
					newObj = { ...newObj, ...objn};
				}else{
					if(nkey){
						const newkey = key[0].toUpperCase() + key.substring(1);
						newObj[`${nkey}${newkey}`] = value;
					}else{
						newObj[key] = value;
					}
				}
			}
		}
		return newObj;
	};

	const handleClose = () => {
		onClose();
		setOrder({});
	};

	const handleSaveUser = async values => {
		try {
			if (orderId) {
				await api.put(`/orders/${orderId}`, values);
			}
			toast.success("Order Updated SuccessFully");
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	const handleOpenTicket = async (contactId) => {
		if (!contactId) return;
		setIsLoading(true);
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user?.id,
				status: "open",
				passCheck: false
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		setIsLoading(false);
	};

	const drawOrderDetails = (items = []) => {
		console.log(items);
		return items?.map((item) => {
			return <Field
				as={TextField}
				label={`Product Id ${item.id}`}
				readonly
				value={`${item.product.name} - ${item.product.price}MYR - ${item.quantity}x`}
				variant="outlined"
				margin="dense"
				fullWidth
			/>
		})
	}

	const paymentMethodMaping = (string) => {
		switch(string){
			case "cod": return "Cash On Delivery";
			case "card": return "Debt/Credit Card";
			case "transfer": return "Bank Transfer";
		}
	}

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="lg"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					View Order
				</DialogTitle>

				<Formik
					initialValues={order}
					enableReinitialize={true}
					validationSchema={OrderSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								{ !isLoading && (
									<>
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel id="profile-selection-input-label">
												Order Status
											</InputLabel>
											<Select
												autoFocus
												variant="outlined"
												margin="dense"
												className={classes.select}
												value={order.orderStatus}
												onChange={e => {
													values.orderStatus = e.target.value;
													setOrder(() => {
														return { ...values, orderStatus: e.target.value };
													});
												}}
											>
												{orderStatus.map((s) =>
													<MenuItem selected={s === order.orderStatus} value={s}> {s} </MenuItem>
												)}
											</Select>
										</FormControl>
										<Field
											as={TextField}
											label="Order ID"
											readonly
											value={order.id}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Total Amount"
											readonly
											value={order.amount + "MYR"}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Shipping Address"
											readonly
											value={order.shippingAddress}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Payment Method"
											readonly
											value={paymentMethodMaping(order.paymentMethod)}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Created At"
											readonly
											value={order.createdAt}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Order Email"
											readonly
											value={order.orderEmail}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Can
											role={user.profile}
											perform="store:showBelongsTo"
											yes={() => bUser && (
												<div className={classes.container}>
													<Container>
														<Typography variant="heading2" >
															Belongs To
														</Typography>
													</Container>
													<Container >
														<Typography variant="heading2" >
															Username: <b> {bUser.name} </b>
														</Typography>
													</Container>
													<Container >
														<Typography variant="heading2" >
															Email: <b> {bUser.email} </b>
														</Typography>
													</Container>
												</div>
											)}
										/>
										<div style={{margin: "20px 0px", fontWeight: "bold"}}>
											Contact
										</div>
										<Field
											as={TextField}
											label="Contact Name"
											readonly
											value={order?.contact?.name}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Contact Email"
											readonly
											value={order?.contact?.email}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Field
											as={TextField}
											label="Contact Number"
											readonly
											value={order?.contact?.number}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<Button
											onClick={() => handleOpenTicket(order?.contact?.id)}
											color="primary"
											variant="outlined"
											type="button"
										>
											Text Contact
										</Button>
										<div style={{margin: "20px 0px", fontWeight: "bold"}}>
											Order Items
										</div>
										{drawOrderDetails(order.orderDetails)}
									</>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{orderId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};



// {
//     "orderDetails": [
//         {
//             "id": 36,
//             "orderId": 31,
//             "productId": 20,
//             "quantity": 3,
//             "price": 1111,
//             "createdAt": "2023-12-09T11:25:14.000Z",
//             "updatedAt": "2023-12-09T11:25:14.000Z",
//             "product": {
//                 "id": 20,
//                 "name": "Nasi Daging",
//                 "price": 1111,
//                 "weight": 111,
//                 "description": "Beef w Rice",
//                 "thumbnail": "http://localhost:8080/public/1702119536073.png",
//                 "image": "",
//                 "categoryId": 2,
//                 "stock": 111,
//                 "createdAt": "2023-12-09T10:43:19.000Z",
//                 "updatedAt": "2023-12-09T10:58:58.000Z"
//             }
//         }
//     ]
// }

export default OrderModal;
