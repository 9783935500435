import React, { useState, useEffect } from "react";

import {
  CssBaseline,
  Typography,
  Container,
  TextField,
  CircularProgress
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/landingpagelogo.png";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/icons/Store";
import Person from "@material-ui/icons/Person";
import Done from "@material-ui/icons/Done";
import Grid from "@material-ui/core/Grid";
import toastError from "../../errors/toastError";
import TabPanel from "../../components/TabPanel";
import api from "../../services/api";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import style from "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
const localizer = momentLocalizer(moment)

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cpaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoStyle: {
    margin: "30px auto",
    width: "25%",
    maxWidth: "200px",
  },
  mainFormContainer: {
    height: "90vh",
    display: "flex",
    alignItems: "center"
  },
  mainFormContainer: {
    height: "100%",
    width: "100%",
  },
  chatPapper: {
    display: "flex",
    height: "100%",
  },
  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  mainbookingsContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "40px",
    margin: "40px 0px"
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    bbookingRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
    bbookingTopRightRadius: 0,
    bbookingBottomRightRadius: 0,
  },
  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },
  tab: {
    minWidth: 120,
    width: 120,
  },
  mainFormContainer: {
    margin: "40px 0px",
    maxWidth: "2000px"
  },
  formContainer: {
    width: "100%",
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cart: {
    position: "fixed",
    bottom: "10%",
    left: "80%",
    width: "70px",
    height: "70px",
    bbookingRadius: "50%",
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryStyle: {
    margin: "20px 0px",
    padding: "10px",
    textAlign: "center",
    bbooking: "1px solid black",
    bbookingRadius: "25px",
  }
}));

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const bookingSchema = Yup.object().shape({
  contactName: Yup.string().required("Required"),
  whatsAppContact: Yup.string().required("Required")
    .matches(phoneRegExp, 'Enter valid Whatsapp number')
    .min(10, "number is too short")
    .max(12, "number is too long"),
});

const nullSchema = Yup.object().shape({});

const optSchema = Yup.object().shape({
  otp: Yup.string().required("Required")
    .min(6, "number is too short")
    .max(6, "number is too long"),
});

const BookingFront = () => {
  const classes = useStyles();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const cid = params.get('cid');
  const uid = params.get('uid');

  const [tab, setTab] = useState("bookings");
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [bookings, setbookings] = useState([]);

  const [to, setto] = useState(null);
  const [from, setfrom] = useState(null);

  const [hash, setHash] = useState("");
  const [createbooking, setCreatebooking] = useState("");

  const initialState = {
    whatsAppContact: "",
    contactName: ""
  };

  const [booking, setbooking] = useState(initialState);

  const formatDateValue = (timestamp) => {
		const format = "YYYY-MM-DD hh:mm a";
		const date = new Date(timestamp);
		return moment(date).format(format);
	};

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setIsLoading(true);
        if(uid){
          const { data } = await api.get("/bookingsfront", {
            params: {
              userId: uid,
              from,
              to 
            }
          });

          const newbooking = data.map((b) => {
            return {
              start: moment(b.opening).toDate(),
              end: moment(b.ends).toDate(),
              title: b.title,
              allDay: false,
              resource: b
            }
          });

          setbookings(newbooking);
          toast("Select slot from calender")
        }
      } catch (err) {
        toastError(err);
      }
      setIsLoading(false);
    };
    fetchSession();
  }, []);

  const handleChangeTab = (newValue) => {
    setTab(newValue);
  };

  const handleSavebooking = async (values = {}) => {
    try {
      const { data } = await api.put(`/bookingsfront/${createbooking.id}`, {
        contactId: cid,
        booked: true,
        ...values
      });

      setHash(data.hash);
      setCreatebooking(data.booking);
      toast.success("OTP is sent to you !");
      handleChangeTab("confirmation");
    } catch (err) {
      toastError(err);
    }
  };

  const handleConfrimbooking = async values => {
    try {
      await api.put(`/bookingsfront/${createbooking.id}`, {
        hash,
        otp: values.otp,
        contactId: createbooking.contactId,
        booked: true
      });

      setIsDone(true)
      toast.success("bookings Added SuccessFully");
    } catch (err) {
      toastError(err);
    }
  };

  const slotSelection = (slot) => {
    setCreatebooking(slot);
    handleChangeTab("info");
  };

  const handleScroll = (e) => {
    if(!Array.isArray(e) && e.constructor === Object){
      setfrom(e.start);
      setto(e.end);
    } else if (Array.isArray(e)) {
      if(e.length >= 2){
        setfrom(e[0]);
        setto(e[e.length - 1]);
      }else{
        setfrom(e[0]);
        setto(null);
      }
    }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
            <Paper elevation={0} square className={classes.tabsHeader}>
              <Tabs
                value={tab}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab
                  value={"bookings"}
                  icon={<Menu />}
                  label="bookings"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  value={"info"}
                  icon={<Person />}
                  label="Personal Info"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  value={"confirmation"}
                  icon={<Done />}
                  label="Confirmation"
                  classes={{ root: classes.tab }}
                />
              </Tabs>
            </Paper>
            <TabPanel value={tab} name="bookings" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="lg">
                <div className={classes.paper}>
                  <img className={classes.logoStyle} src={logo} alt="Logo" />
                  <Typography component="h1" variant="h5">
                    TanyaJE Calendar
                  </Typography>
                  <br/><br/>
                  <Calendar
                    localizer={localizer}
                    events={bookings}
                    onRangeChange={(range) => handleScroll(range)}
                    onSelectEvent={(event) => slotSelection(event.resource)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 670, width: "100%" }}
                  />
                </div>
              </Container>
            </TabPanel>

            <TabPanel value={tab} name="info" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.cpaper}>
                  <div className={classes.formContainer}>
                    <img className={classes.logoStyle} src={logo} alt="Logo" />
                    <Typography component="h1" variant="h4">
                      Information
                    </Typography>
                  </div>
                  <div className={classes.formContainer}>
                    <Typography component="h2" variant="h5" style={{margin: "10px 0px"}}>
                      Slot Selected
                    </Typography>
                    <Typography component="h5" variant="h6" style={{textAlign: "center", margin: "10px 0px"}}>
                      from : {formatDateValue(createbooking.opening)}
                      <br/>
                      to : {formatDateValue(createbooking.ends)}
                    </Typography>
                    <Formik
                      initialValues={booking}
                      enableReinitialize={true}
                      validationSchema={cid ? nullSchema : bookingSchema}
                      onSubmit={(values, actions) => {
                        setTimeout(() => {
                          handleSavebooking(values);
                          actions.setSubmitting(false);
                        }, 400);
                      }}
                    >
                      {({ touched, errors, isSubmitting, values }) => (
                        <Form className={classes.formContainer}>
                          {
                            !cid &&
                            <Field
                              as={TextField}
                              label="WhatsApp Contact"
                              name="whatsAppContact"
                              error={touched.whatsAppContact && Boolean(errors.whatsAppContact)}
                              helperText={touched.whatsAppContact && errors.whatsAppContact}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          }
                          {
                            !cid &&
                            <Field
                              as={TextField}
                              label="Full Name"
                              name="contactName"
                              error={touched.contactName && Boolean(errors.contactName)}
                              helperText={touched.contactName && errors.contactName}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          }
                          {isSubmitting && <CircularProgress />}
                          {
                            !isSubmitting && (
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={{ margin: "20px 0px 10px 0px", width: "200px" }}
                              >
                                Confrim Booking
                              </Button>
                            )
                          }
                          <Button
                            color="primary"
                            type="button"
                            variant="outlined"
                            style={{ margin: "10px 0px", width: "200px" }}
                            onClick={() => handleChangeTab("bookings")}
                          >
                            Return
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Container>
            </TabPanel>
            <TabPanel value={tab} name="confirmation" className={classes.ticketsWrapper}>
              <Container className={classes.mainFormContainer} component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <img className={classes.logoStyle} src={logo} alt="Logo" />
                  {
                    isDone && (
                      <Typography component="h1" variant="h5">
                        Booking Confrimed
                      </Typography>
                    )
                  }
                  {
                    !isDone && (
                      <>
                        <Typography component="h1" variant="h5">
                          Confrim
                        </Typography>
                        <Formik
                          initialValues={booking}
                          enableReinitialize={true}
                          validationSchema={optSchema}
                          onSubmit={(values, actions) => {
                            setTimeout(() => {
                              handleConfrimbooking(values);
                              actions.setSubmitting(false);
                            }, 400);
                          }}
                        >
                          {({ touched, errors, isSubmitting, values }) => (
                            <Form style={{ width: "100%", maxWidth: "700px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <Field
                                as={TextField}
                                label="OTP"
                                autoFocus
                                name="otp"
                                error={touched.otp && Boolean(errors.otp)}
                                helperText={touched.otp && errors.otp}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                              />
                              {isSubmitting && <CircularProgress />}
                              {
                                !isSubmitting && (
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    style={{ margin: "10px 0px", width: "200px" }}
                                  >
                                    Verfiy
                                  </Button>
                                )
                              }
                            </Form>
                          )}
                        </Formik>
                      </>
                    )
                  }
                </div>
              </Container>
            </TabPanel>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};

export default BookingFront;
