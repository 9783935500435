const rules = {
	user: {
		static: [],
		dynamic: {
			"store:access": function(storeEnabled){
				return storeEnabled;
			},
			"booking:access": function(bookingEnabled){
				return bookingEnabled;
			},
			"trackable:access": function(trackableEnabled){
				return trackableEnabled;
			},
		}
	},
	admin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"user-modal:editDigitalMu",
			"user-modal:booking",
			"user-modal:trackable",
			"ticket-options:deleteTicket",
			"ticket-options:transferWhatsapp",
			"contacts-page:deleteContact",
			"store:settings",
			"store:access",
			"trackable:showBelongsTo",
			"store:showBelongsTo",
			"booking:access",
			"trackable:access",
			// "contacts-page:importContact",
		],
	},
};

export default rules;
