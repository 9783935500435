import React, { useState, useEffect } from "react";

import {
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Grid,
  Container,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel,
  FormControl
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";

import api from "../../services/api";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import logo from "../../assets/landingpagelogo.png";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  wrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },
  logoStyle: {
    margin: "30px auto",
    width: "25%",
    maxWidth: "200px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoStyle: {
    margin: "30px auto",
    width: "25%",
    maxWidth: "200px",
  },
  tab: {
    minWidth: 120,
    width: 120,
  },
  mainFormContainer: {
    margin: "40px 0px",
    maxWidth: "2000px"
  },
  btnWrapper: {
    margin: "30px auto",
    width: "100%"
  }
}));

const LogSchema = Yup.object().shape({
  note: Yup.string(),
  address: Yup.string(),
  type: Yup.string().required("Required"),
});

const TrackablesFront = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const tid = params.get('tid');

  const initialState = {
    note: "",
    address: "",
    type: null,
  };

  const typesm = {
    "CHECKIN": "Check In",
    "CHECKOUT": "Check Out",
  };

  const [log, setLogs] = useState(initialState);
  const [trackable, setTrackable] = useState({});

  const handleClose = () => {
    setLogs(initialState);
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (!tid) return;
      setLoading(true)
      try {
        const { data } = await api.get(`/trackables/${tid}`);
        setTrackable(prevState => {
          return { ...prevState, ...data };
        });

      } catch (err) {
        toastError(err);
      }

      setLoading(false)
    };

    fetchUser();
  }, []);

  const handleSaveTrackable = async values => {
    try {
      if (!tid) {
        toast.error("Invalid Trackable Link Used");
        return;
      }

      setLoading(true);

      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      await api.post("/logs", {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
        trackableItemId: tid,
        ...values
      });
      toast.success("Log Added SuccessFully");
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }

    handleClose();
  };
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const snakeToCamel = str => str.toLowerCase().replace(/([-_][a-z])/g, group => capitalizeFirstLetter( group.replace('-', ' ').replace('_', ' ') ) );

  return (
    <MainContainer>
      <Paper elevation={0} variant="outlined" className={classes.wrapper}>

        <Container className={classes.mainFormContainer} component="main" maxWidth="lg">
          <div className={classes.paper}>
            <img className={classes.logoStyle} src={logo} alt="Logo" />
            <Typography component="h1" variant="h5">
              TanyaJE Trackables
            </Typography>
            <br />
            {
              !loading && trackable.name && (
                <Typography component="p" variant="p">
                  {trackable.name}
                </Typography>
              )
            }
            {
              !loading && trackable.desc && (
                <Typography component="p" variant="p">
                  {trackable.desc}
                </Typography>
              )
            }
            <br /><br />
            <Formik
              initialValues={log}
              enableReinitialize={true}
              validationSchema={LogSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveTrackable(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                <Form>
                  <Field
                    as={TextField}
                    label="Note (Optional)"
                    autoFocus
                    name="note"
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    label="Address (Optional)"
                    name="address"
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
                    <InputLabel id="profile-selection-input-label">
                      Action
                    </InputLabel>
                    <Select
                      label="Select Type"
                      autoFocus
                      helperText={"Types"}
                      variant="outlined"
                      margin="dense"
                      className={classes.select}
                      value={log.type}
                      onChange={e => {
                        values.type = e.target.value;
                        setLogs(() => {
                          return { ...values, type: e.target.value };
                        });
                      }}
                    >
                      {Object.entries(typesm).map((q) => <MenuItem selected={log.type === q[0]} value={ q[0] }> { q[1] } </MenuItem>)}
                    </Select>
                  </FormControl>


                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    Submit
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Form>
              )}
            </Formik>

          </div>
        </Container>

      </Paper>
    </MainContainer>
  );
};

export default TrackablesFront;
