import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import { toast } from "react-toastify";

import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Delete from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import ProductModal from "../ProductModal";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_PRODUCTS") {
        const products = action.payload;
        const newProducts = [];

        products.forEach((product) => {
            const productIndex = state.findIndex((c) => c.id === product.id);
            if (productIndex !== -1) {
                state[productIndex] = product;
            } else {
                newProducts.push(product);
            }
        });

        return [...state, ...newProducts];
    }

    if (action.type === "UPDATE_PRODUCTS") {
        const product = action.payload;
        const productIndex = state.findIndex((c) => c.id === product.id);

        if (productIndex !== -1) {
            state[productIndex] = product;
            return [...state];
        } else {
            return [product, ...state];
        }
    }

    if (action.type === "DELETE_PRODUCTS") {
        const productId = action.payload;
        const productIndex = state.findIndex((c) => c.id === productId);
        if (productIndex !== -1) {
            state.splice(productIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Products = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductxsId, setSelectedProductsId] = useState([]);

    const [productsModalOpen, setProductsModalOpen] = useState(false);
    const [deletingProducts, setDeletingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const [products, dispatch] = useReducer(reducer, []);
    const [categories, setCategories] = useState([]);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        setPageNumber(1);
        dispatch({ type: "RESET" });
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("/products/", {
                        params: { searchParam, pageNumber },
                    });

                    dispatch({ type: "LOAD_PRODUCTS", payload: data.products });

                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket();

        socket.on("product", (data) => {
            if (user.profile === "user" && data.userId !== user.id) {
                return;
            }

            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_PRODUCTS", payload: data.product });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_PRODUCTS", payload: +data.productId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        const fetchContacts = async () => {
            try {
                const { data } = await api.get("/categories/", {
                    params: { returnAll: "1" },
                });

                setCategories(data.categories)
                setLoading(false);
            } catch (err) {
                toastError(err);
            }
        };
        fetchContacts();
    }, []);


    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenProductModal = () => {
        setSelectedProductId(null);
        setProductsModalOpen(true);
    };

    const hadleEditProduct = (id) => {
        setSelectedProductId(id);
        setProductsModalOpen(true);
    };

    const handleDeleteProduct = async (id) => {
        try {
            await api.delete(`/products/${id}`);
            toast.success("Product Deleted");
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleCheckboxSelection = (e, cid) => {
        setSelectedProductsId(ids =>
            e.target.checked === true ? [...ids, cid] : ids.filter((id) => id !== cid)
        );
    };

    const handleCloseProductModal = () => {
        setSelectedProductId(null);
        setProductsModalOpen(false);
        setPageNumber(1);
    };

    return (
        <MainContainer className={classes.mainContainer}>

            <ConfirmationModal
                title={
                    deletingProducts &&
                    `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingProducts.name} ?`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={() => handleDeleteProduct(deletingProducts.id)}
            >
                Are you you want to delete this product ?
            </ConfirmationModal>

            <ProductModal
                open={productsModalOpen}
                onClose={handleCloseProductModal}
                aria-labelledby="form-dialog-title"
                userId={selectedProductId}
                categories={categories}
            />

            <MainHeader>
                <Title> Products </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenProductModal}
                    >
                        Add Product
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Select
                            </TableCell>
                            <TableCell> Image </TableCell>
                            <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                            <TableCell align="center">
                                Category
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("contacts.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        <input type="checkbox" onChange={(e) => handleCheckboxSelection(e, product.id)} />
                                    </TableCell>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={product.thumbnail} />}
                                    </TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell align="center"> {categories.filter((e) => e.id == product.categoryId)[0]?.name ?? "None"} </TableCell>
                                    <TableCell align="center">

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setConfirmOpen(true);
                                                setDeletingContact(product);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => hadleEditProduct(product.id)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Products;
