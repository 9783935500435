import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	TextField,
	Container,
	Typography
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import { Can } from "../Can"
import UploadField from "../UploadFeild"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},
	container: {
		border: "1px solid grey",
		padding: "5px 0px",
		margin: "5px 0px",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
	description: Yup.string(),
	thumbnail: Yup.string()
});

const CategoryModal = ({ open, onClose, userId }) => {
	const classes = useStyles();


	const initialState = {
		name: "",
		description: "",
		thumbnail: "",
	};

	const [category, setCategory] = useState(initialState);

	const [bUser, setBUser] = useState(null);
	const { user } = useContext(AuthContext);


	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/categories/${userId}`);
				setCategory(prevState => {
					return { ...prevState, ...data };
				});

				if(data.userId && user.profile === "admin"){
					const user = await api.get(`/users/${data.userId}`);
					setBUser(user.data);
				}else{
					setBUser(null);
				}
				
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setCategory(initialState);
	};

	const handleSaveUser = async values => {
		try {
			if (userId) {
				await api.put(`/categories/${userId}`, values);
			} else {
				await api.post("/categories", values);
			}
			toast.success("Category Added SuccessFully");
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId ? `Add Category` : `Edit Category`}
				</DialogTitle>
				<Formik
					initialValues={category}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("userModal.form.name")}
									autoFocus
									name="name"
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<Field
									as={TextField}
									label="Description"
									name="description"
									error={touched.description && Boolean(errors.description)}
									helperText={touched.description && errors.description}
									variant="outlined"
									margin="dense"
									fullWidth
								/>
								<UploadField
									label={"Thumbnail"}
									initalValue={category.thumbnail}
									onChange={(v) => {
										values.thumbnail = v;
										setCategory(() => {
											return { ...values, thumbnail: v };
										});
									}}
								/>
								<Can
									role={user.profile}
									perform="store:showBelongsTo"
									yes={() => bUser && (
										<div className={classes.container}>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Belongs To
												</Typography>
											</Container>
											<Container  maxWidth="sm">
												<Typography variant="heading2" >
													Username: <b> {bUser.name} </b>
												</Typography>
											</Container>
											<Container maxWidth="sm">
												<Typography variant="heading2" >
													 Email: <b> {bUser.email} </b>
												</Typography>
											</Container>
										</div>
									)}
								/>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default CategoryModal;
