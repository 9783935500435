import React, { useState, useEffect, useReducer, useContext, useCallback } from "react";
import openSocket from "../../services/socket-io";

import {
  Button,
  makeStyles,
  Paper,
  Container,
  Typography,
  TextField,
} from "@material-ui/core";

import TabPanel from "../../components/TabPanel";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { Can } from "../../components/Can";
import BookingModal from "../../components/BookingModal";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppleIcon from "@material-ui/icons/Storefront";
import SettingsIcon from "@material-ui/icons/Settings";
import SortIcon from "@material-ui/icons/Sort";

import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";

import style from "react-big-calendar/lib/css/react-big-calendar.css";
import cstyle from "./custom-calendar.css";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
const localizer = momentLocalizer(moment);

const reducer = (state, action) => {
  if (action.type === "LOAD_BOOKING") {
    const booking = action.payload;
    const newbooking = [];
    booking.forEach((b) => {
      const bIndex = state.findIndex((q) => q.resource.id === b.id);
      if (bIndex !== -1) {
        state[bIndex] = {
          start: moment(b.opening).toDate(),
          end: moment(b.ends).toDate(),
          title: b.title,
          allDay: false,
          resource: b
        };
      } else {
        newbooking.push({
          start: moment(b.opening).toDate(),
          end: moment(b.ends).toDate(),
          title: b.title,
          allDay: false,
          resource: b
        });
      }
    });
    return [...state, ...newbooking];
  }

  if (action.type === "UPDATE_BOOKING") {
    const booking = action.payload;
    const bookingIndex = state.findIndex((q) => q.resource.id === booking.id);
    if (bookingIndex !== -1) {
      state[bookingIndex] = {
        start: moment(booking.opening).toDate(),
        end: moment(booking.ends).toDate(),
        title: booking.title,
        allDay: false,
        resource: booking
      };
      return [...state];
    } else {
      return [{
        start: moment(booking.opening).toDate(),
        end: moment(booking.ends).toDate(),
        title: booking.title,
        allDay: false,
        resource: booking
      }, ...state];
    }
  }

  if (action.type === "DELETE_BOOKING") {
    const bookingId = action.payload;
    const bookingIndex = state.findIndex((q) => q.resource.id === bookingId);
    if (bookingIndex !== -1) {
      state.splice(bookingIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  paperColumn: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: 12,
  },
  settingOption: {
    marginLeft: "auto",
  },
  settingFullOption: {
    width: "100%",
    marginLeft: "auto",
  },
  root: {
    padding: "64px 0px",
    overflow: 'auto'
  },
}));

const Booking = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [to, setto] = useState(1);
  const [from, setfrom] = useState("");
  const [booking, dispatch] = useReducer(reducer, []);
  const [selectedbooking, setSelectedbooking] = useState(null);
  const [bookingModalOpen, setbookingModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);
  const [tab, setTab] = useState("open");
  const [isLoading, setIsLoading] = useState(false);

  const [settings, setSettings] = useState([]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setto(1);
  }, [from]);

  useEffect(() => {
    setLoading(true);
    setTab("booking");

    const delayDebounceFn = setTimeout(() => {
      const fetchBooking = async () => {
        try {
          const { data } = await api.get("/booking/", {
            params: { from, to },
          });
          dispatch({ type: "LOAD_BOOKING", payload: data.bookings });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchBooking();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [bookingModalOpen, to]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get("/settings");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
      setIsLoading(false);
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("booking", (data) => {
      if (user.profile === "user" && data.userId !== user.id) {
        return;
      }

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_BOOKING", payload: data.booking });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_BOOKING",
          payload: +data.bookingId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenbookingModal = () => {
    setSelectedbooking(null);
    setbookingModalOpen(true);
  };

  const handleClosebookingModal = () => {
    setSelectedbooking(null);
    setbookingModalOpen(false);
  };

  const handleEditbooking = (id) => {
    setSelectedbooking(id);
    setbookingModalOpen(true);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleScroll = (e) => {
    if (!Array.isArray(e) && e.constructor === Object) {
      setfrom(e.start);
      setto(e.end);
    } else if (Array.isArray(e)) {
      if (e.length >= 2) {
        setfrom(e[0]);
        setto(e[e.length - 1]);
      } else {
        setfrom(e[0]);
        setto(null);
      }
    }
  };

  const getSettingValue = key => {
    let keySearch = key;

    if (user.profile === "user") {
      keySearch += ":" + user.id;
    }

    const value = settings.find(s => s.key === keySearch);
    return value?.value ?? "";
  };

  const saveSettings = async (selectedValue, settingKey) => {
    try {
      setIsLoading(true);

      let key = settingKey;
      if (user.profile === "user") {
        key += ":" + user.id;
      }

      await api.put(`/settings/${key}`, {
        value: selectedValue,
      });

      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
    setIsLoading(false);
  };

  const setSettingValue = (e, key) => {
    let keySearch = key;

    if (user.profile === "user") {
      keySearch += ":" + user.id;
    }

    const value = settings.find(s => s.key === keySearch);
    if (value) {
      value.value = e.target.value;
      setSettings(settings => [
        ...settings.filter((s) => s.key !== key),
        value
      ]);
    }
  };

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.resource.booked && {
        className: 'bookedColor',
      }),
    }),
    []
  )

  return (
    <MainContainer>
      <Can
        role={user.profile}
        perform="booking:access"
        data={user.bookingEnabled}
        no={() => (
          <Container className={classes.container} maxWidth="sm">
            <Typography variant="h2" gutterBottom>
              No Access Permission
            </Typography>
          </Container>
        )}
        yes={() => (
          <>
            <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
              <Paper elevation={0} square className={classes.tabsHeader}>
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon label tabs example"
                >
                  <Tab
                    value={"booking"}
                    icon={<AppleIcon />}
                    label="Booking"
                    classes={{ root: classes.tab }}
                  />
                  <Tab
                    value={"settings"}
                    icon={<SettingsIcon />}
                    label="Settings"
                    classes={{ root: classes.tab }}
                  />
                </Tabs>
              </Paper>
              <TabPanel value={tab} name="booking" className={classes.ticketsWrapper}>
                <BookingModal
                  open={bookingModalOpen}
                  onClose={handleClosebookingModal}
                  aria-labelledby="form-dialog-title"
                  bookingId={selectedbooking}
                ></BookingModal>
                <MainHeader>
                  <Title> Bookings </Title>
                  <MainHeaderButtonsWrapper>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenbookingModal}
                    >
                      Add New Slot
                    </Button>
                  </MainHeaderButtonsWrapper>
                </MainHeader>
                <Paper
                  className={classes.mainPaper}
                  variant="outlined"
                >
                  <Calendar
                    localizer={localizer}
                    events={booking}
                    loading={loading}
                    eventPropGetter={eventPropGetter}
                    onRangeChange={(range) => handleScroll(range)}
                    onSelectEvent={(event) => {
                      handleEditbooking(event.resource.id);
                    }}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: "650px" }}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} name="settings" className={classes.ticketsWrapper}>
                <div className={classes.root}>
                  <Container className={classes.container} maxWidth="sm">
                    <Typography variant="body2" gutterBottom>
                      Settings
                    </Typography>
                    <Paper className={classes.paperColumn}>
                      <Typography variant="body1">
                        Booking Greeting Text
                      </Typography>
                      {
                        !isLoading && (
                          <TextField
                            name="bookingGreeting"
                            onChange={(e) => setSettingValue(e, "bookingGreeting")}
                            className={classes.settingFullOption}
                            defaultValue={
                              settings && settings.length > 0 && getSettingValue("bookingGreeting")
                            }
                          />
                        )
                      }
                      <Button
                        variant="outlined"
                        margin="dense"
                        style={{ marginTop: "20px" }}
                        native
                        onClick={() => saveSettings(getSettingValue("bookingGreeting"), "bookingGreeting")}
                      > Save </Button>
                    </Paper>
                    <Paper className={classes.paperColumn}>
                      <Typography variant="body1">
                        Booking Link
                      </Typography>
                      <TextField
                        className={classes.settingFullOption}
                        value={`${window.location.hostname}/bookingfront?uid=${user.id}`}
                      />
                    </Paper>
                  </Container>
                </div>
              </TabPanel>
            </Paper>
          </>
        )}
      />
    </MainContainer>
  );
};

export default Booking;
